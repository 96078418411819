import { invokeApi } from "src/bl_libs/invokeApi";

export const _get_chat_message = async (url) => {
  const requestObj = {
    path: `${url}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const _get_chat_history = async (path, search) => {
  const requestObj = {
    path: `${path}&search=${search ? search : ""}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const update_image_on_s3_for_chat = async (data) => {
  const requestObj = {
    path: `api/chat/upload_chat_image`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _read_message = (chat_id) => {
  const requestObj = {
    path: `api/chat/read_message/${chat_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const _member_list_with_search = async (search) => {
  const requestObj = {
    path: `api/chat/users?search=${search ? search : ""}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const _check_chat_existing = async (reciver_id) => {
  const requestObj = {
    path: `api/chat/list_messages_by_member/${reciver_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _get_unread_user_list = async () => {
  const requestObj = {
    path: `api/chat/unread_chats`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
